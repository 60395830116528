/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SourceTypes } from '../models/SourceTypes';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportingService {

    /**
     * @param viewId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetViewData(
viewId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetViewData',
            query: {
                'viewId': viewId,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetIdentifiedCsvData(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetIdentifiedCsvData',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetIdentifiedCsvDataBySite(
trialId?: number,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetIdentifiedCsvDataBySite',
            query: {
                'trialId': trialId,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetIdentifiedCsvDataOldFormat(
trialId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetIdentifiedCsvDataOldFormat',
            query: {
                'trialId': trialId,
            },
        });
    }

    /**
     * @param trialId 
     * @param siteIdArray 
     * @param selectedSiteId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetEnrolmentWidgetData(
trialId?: number,
siteIdArray: string = '0',
selectedSiteId: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetEnrolmentWidgetData',
            query: {
                'trialId': trialId,
                'siteIdArray': siteIdArray,
                'selectedSiteId': selectedSiteId,
            },
        });
    }

    /**
     * @param trialId 
     * @param siteIdArray 
     * @param selectedSiteId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetReferralWidgetData(
trialId?: number,
siteIdArray: string = '0',
selectedSiteId: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetReferralWidgetData',
            query: {
                'trialId': trialId,
                'siteIdArray': siteIdArray,
                'selectedSiteId': selectedSiteId,
            },
        });
    }

    /**
     * @param trialId 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetRegistrationWidgetData(
trialId?: number,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetRegistrationWidgetData',
            query: {
                'trialId': trialId,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetPerformanceWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetPerformanceWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetAwaitingActionWidgetData(
trialId?: number,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetAwaitingActionWidgetData',
            query: {
                'trialId': trialId,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @param state 
     * @param stageId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetDqReasonsWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
state: string = '',
stageId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetDqReasonsWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
                'state': state,
                'stageId': stageId,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @param state 
     * @param stageId 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetWdReasonsWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
state: string = '',
stageId?: number,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetWdReasonsWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
                'state': state,
                'stageId': stageId,
            },
        });
    }

    /**
     * @param trialId 
     * @param taskId1 
     * @param actionId1 
     * @param taskId2 
     * @param actionId2 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetConversionRateWidgetData(
trialId?: number,
taskId1?: number,
actionId1?: number,
taskId2?: number,
actionId2?: number,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetConversionRateWidgetData',
            query: {
                'trialId': trialId,
                'taskId1': taskId1,
                'actionId1': actionId1,
                'taskId2': taskId2,
                'actionId2': actionId2,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @param stageId 
     * @param state 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetParticipantJourneyWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
stageId?: number,
state: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetParticipantJourneyWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
                'stageId': stageId,
                'state': state,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param taskId 
     * @param actionId 
     * @param frequency 
     * @param siteIdArray 
     * @param state 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetTaskByDateRangeWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
taskId?: number,
actionId?: number,
frequency?: number,
siteIdArray: string = '0',
state: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetTaskByDateRangeWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'taskId': taskId,
                'actionId': actionId,
                'frequency': frequency,
                'siteIdArray': siteIdArray,
                'state': state,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param taskId 
     * @param actionId 
     * @param siteIdArray 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetSiteOverviewWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
taskId?: number,
actionId?: number,
siteIdArray: string = '0',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetSiteOverviewWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'taskId': taskId,
                'actionId': actionId,
                'siteIdArray': siteIdArray,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @param stageId 
     * @param state 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetDqOtherReasonsWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
stageId?: number,
state: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetDqOtherReasonsWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
                'stageId': stageId,
                'state': state,
            },
        });
    }

    /**
     * @param trialId 
     * @param startDate 
     * @param endDate 
     * @param siteIdArray 
     * @param stageId 
     * @param state 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetWdOtherReasonsWidgetData(
trialId?: number,
startDate?: string,
endDate?: string,
siteIdArray: string = '0',
stageId?: number,
state: string = '',
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetWdOtherReasonsWidgetData',
            query: {
                'trialId': trialId,
                'startDate': startDate,
                'endDate': endDate,
                'siteIdArray': siteIdArray,
                'stageId': stageId,
                'state': state,
            },
        });
    }

    /**
     * @param callingUserCognitoSub 
     * @param trialId 
     * @param stagePriority 
     * @param taskId 
     * @param taskState 
     * @param startDate 
     * @param endDate 
     * @param sourceTypes 
     * @returns any OK
     * @throws ApiError
     */
    public static getApiReportingGetSourceWidgetData(
callingUserCognitoSub?: string,
trialId?: number,
stagePriority?: number,
taskId?: number,
taskState?: number,
startDate?: string,
endDate?: string,
sourceTypes?: SourceTypes,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/Reporting/GetSourceWidgetData',
            query: {
                'callingUserCognitoSub': callingUserCognitoSub,
                'trialId': trialId,
                'stagePriority': stagePriority,
                'taskId': taskId,
                'taskState': taskState,
                'startDate': startDate,
                'endDate': endDate,
                'sourceTypes': sourceTypes,
            },
        });
    }

}
